<template>
    <st-page
        :title="$t('CONTROL.PAGE_HEADER')"
    >
        <loader v-if="isLoading"/>
        <template #toolbar>
            <st-button
                variant="secondary"
                :callback="addControl"
            >
                <i class="fa fa-plus"></i>
                {{ $t('CONTROL.BUTTON.ADD_CONTROL') }}
            </st-button>
        </template>

        <control-list-filter v-if="initLoading">
            <control-list-table @editControl="editControl"/>
        </control-list-filter>

        <select-organisation-modal
            ref="selectOrganisationModal"
            @organisationSelected="organisationSelected"
        ></select-organisation-modal>
    </st-page>
</template>

<script>
    import ControlListFilter from '../components/ControlListFilter';
    import ControlListTable from '../components/ControlListTable';
    import SelectOrganisationModal from '@/modules/administrator/components/modals/SelectOrganisationModal.vue';
    import { mapGetters, mapActions } from 'vuex';
    import Roles from '@/security/roles';

    export default {
        name: 'ControlList',
        components: {
            ControlListFilter,
            ControlListTable,
            SelectOrganisationModal
        },
        data() {
            return {
                selectedControl: {},
                editMode: false,
                initLoading: false,
            };
        },
        computed: {
            ...mapGetters({
                loading: 'shared/loading',
                currentUser: 'auth/currentUser',
            }),
            isLoading () {
                return this.loading['control/getControls'] ;
            },
            isSuperAdmin() {
                return this.currentUser.user_type_name === Roles.values['super_admin']
            }
        },
        mounted() {
            if (this.isSuperAdmin) {
                this.initLoading = false;
                this.$refs.selectOrganisationModal.show();
            } else {
                this.initLoading = true;
            }
        },
        methods: {
            ...mapActions({
                selectRecord: 'control/form/find',
            }),
            addControl() {
                this.$router.push({ name: 'controlNew' });
            },
            async editControl(data) {
                if (!data?.id) return;
                await this.selectRecord(data.id);

                this.$router.push({
                    name: 'controlEdit',
                    params: {
                        id: data.id,
                    },
                });
            },
            organisationSelected() {
                this.initLoading = true;
                this.$refs.selectOrganisationModal.hide();
            },
        }
    }
</script>
